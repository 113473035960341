<template>
    <div>
        <iframe :src="`scorm/${course_id}/${executableFile}`" class="scorm-player"></iframe>
    </div>
</template>
<script>
import "scorm-again";

export default {
    props: [
        'course_id',
        'scormProgress',
        'executableFile'
    ],
    data: function () {
        return {
            settings: {
                autocommit: true,
                autocommitSeconds: 5,
                dataCommitFormat: 'flattened',
                lmsCommitUrl: process.env.VUE_APP_API_URL + '/employees/scorm-progress?courseId=' + this.course_id,
                xhrHeaders: {
                    'authorization': 'Bearer ' + localStorage.getItem("hot-token"),
                    'content-type': 'application/json'
                },
                responseHandler: (data) => {
                    var response = JSON.parse(data.response);
                    this.status = response.status;
                    if (response.scormPassed === 1 && this.isAPICAlled === false) {
                        this.isAPICAlled = true;
                        this.submitEmployeeData();
                    }
                },
            },
            status: 0,
            isAPICAlled: false,
        };
    },
    created: function () {
        window.API = new Scorm12API(this.settings);

        let keyValues = [];
        this.scormProgress.forEach(data => {
            keyValues[data.key] = data.value;
        });

        window.API.cmi.suspend_data = keyValues['cmi.suspend_data'];
    },
    methods: {
        submitEmployeeData: function () {
            setTimeout(() => {
                var formattedLessontest = {
                    is_last_lesson: 1,
                    course_id: this.course_id,
                    test_id: 0,
                    percentageScored: window.API.cmi ? window.API.cmi.core.score.raw : 0,
                    resultStatus: this.status,
                    test_type: "scorm",
                    isScorm: 1,
                }
                this.$emit('save-course-completion', formattedLessontest);
            }, 5000);
        }
    }
}
</script>
<style scoped>
iframe {
    height: 80vh;
    width: calc(100vw - 340px);
}
</style>