var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"content"},[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-2"},[_c('div',{staticClass:"col-lg-6 col-7"})])]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"footer-classes":"pt-1"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 text-left"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Courses Catalog")])])])]),_c('div',[_c('div',{staticClass:"row d-flex justify-content-center justify-content-sm-between flex-wrap mb-2"},[_c('div',{staticClass:"col-md-5"},[_c('label',[_vm._v("Search:")]),_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Search..."},on:{"keyup":function($event){return _vm.fetchData()}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),(
                _vm.editor !== 'FTM' &&
                _vm.editor !== 'manager' &&
                _vm.editor !== 'manager_d_r' &&
                _vm.editor !== 'general-manager'
              )?_c('div',{staticClass:"col-md-4 form-group"},[_c('label',[_vm._v("Status:")]),_c('el-select',{attrs:{"placeholder":"Filter by Course Status"},on:{"change":function($event){return _vm.changePage(1)}},model:{value:(_vm.filters.courseStatus),callback:function ($$v) {_vm.$set(_vm.filters, "courseStatus", $$v)},expression:"filters.courseStatus"}},_vm._l((_vm.status),function(item){return _c('el-option',{key:item.value,staticClass:"select-default",attrs:{"label":item.label,"value":item.value}})}),1)],1):_vm._e(),_c('div',{staticClass:"col-md-3 form-group"},[_c('label',[_vm._v("Showing:")]),_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},on:{"change":function($event){return _vm.changePage(1)}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},_vm._l((_vm.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1)]),_c('div',{staticClass:"user-eltable"},[_c('el-table',{staticClass:"coursesGrid",attrs:{"data":_vm.tableData,"row-key":"id","role":"table","header-row-class-name":"thead-light custom-thead-light"}},[_c('el-table-column',{attrs:{"align":"left","min-width":"400px","label":"Course Name","prop":"course_name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":'/company_course_details?id=' +
                      props.row.id +
                      '&certificate_id=' +
                      props.row.certificate_id}},[(_vm.secondaryCourseCheack && props.row.secondary_course_name)?_c('span',[_vm._v(_vm._s(props.row.secondary_course_name))]):_c('span',[_vm._v(_vm._s(props.row.course_name))])])]}}])}),_c('el-table-column',{attrs:{"align":"left","min-width":"200px","label":"Course Length (mins)"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.row.course_length))])]}}])}),_c('el-table-column',{attrs:{"align":"left","min-width":"200px","label":"Assigned Employees"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.assigned_employees)+" ")]}}])}),(
                  _vm.editor != 'manager_d_r' &&
                  _vm.editor != 'FTM' &&
                  _vm.editor !== 'general-manager'
                )?_c('div',[(_vm.editor != 'manager')?_c('el-table-column',{attrs:{"min-width":"120px","label":"Status","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"d-flex",on:{"click":function($event){return _vm.changeStatus(props.$index, props.row)}}},[(props.row.company_course_status)?_c('base-switch',{staticClass:"mr-1",attrs:{"type":"success"},model:{value:(props.row.company_course_status),callback:function ($$v) {_vm.$set(props.row, "company_course_status", $$v)},expression:"props.row.company_course_status"}}):_c('base-switch',{staticClass:"mr-1",attrs:{"type":"danger"},model:{value:(props.row.company_course_status),callback:function ($$v) {_vm.$set(props.row, "company_course_status", $$v)},expression:"props.row.company_course_status"}})],1)]}}],null,false,2453141198)}):_vm._e()],1):_vm._e()],1)],1)]),_c('div',{staticClass:"d-flex justify-content-end",attrs:{"slot":"footer"},slot:"footer"},[(_vm.pagination && _vm.tableData.length > 0)?_c('nav',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ul',{staticClass:"pagination custompagination justify-content-end align-items-center"},[_c('p',{staticClass:"p-0 m-0 mr-2"},[_vm._v(" Showing "+_vm._s(_vm.tableData.length)+" of "+_vm._s(_vm.totalData)+" entries ")]),_c('li',{staticClass:"page-item",class:{ disabled: _vm.currentPage === 1 }},[_c('a',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.currentPage - 1)}}},[_c('i',{staticClass:"fa fa-caret-left"})])]),_vm._l((_vm.pagesNumber),function(page,index){return _c('li',{key:index,staticClass:"page-item",class:{ active: page == _vm.currentPage }},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(page)}}},[_vm._v(_vm._s(page))])])}),_c('li',{staticClass:"page-item",class:{
                      disabled: _vm.currentPage === _vm.last_page,
                    }},[_c('a',{staticClass:"page-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.currentPage + 1)}}},[_c('i',{staticClass:"fa fa-caret-right"})])])],2)])])]):_vm._e()])],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }